import { useCallback, useRef, useState, type MouseEvent } from "react";
import { defineMessages, useIntl } from "react-intl";
import classnames from "classnames";

import { Portal } from "util/html";
import Overlay from "common/modals/overlay";
import Icon from "common/core/icon";
import { IconButton } from "common/core/button/icon_button";
import { useDragWithLocation, type DragLocation } from "common/core/hooks/use-drag";
import { PDFViewerWithControls } from "common/pdf/pspdfkit/viewer";
// We use the pdf control button to share styles and get a consistent experience between
// pdf and picture viewing.
import { PDFControlButton as ControlButton } from "common/pdf/pspdfkit/util";
import {
  getSpecificDocumentTitleFromClaimedType,
  getGenericDocumentTitleFromType,
  type IdentityDocumentViewerEngine,
} from "common/identity/document_viewer";
import type { RetakeManager } from "common/identity_verification/retake/notary";

import DocumentPlaceholder from "../document_placeholder";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  closeButton: {
    id: "ba47676f-a6e6-4fa0-ae0f-26dfb1e5779d",
    defaultMessage: "Close credential viewer",
  },
  viewOtherSide: {
    id: "323f6859-a8c8-45b0-a8bb-1f5fb5718119",
    defaultMessage: "View other side",
  },
  rotateClock: {
    id: "3efd84d8-7e5e-47f4-a2fb-396a3d223cef",
    defaultMessage: "Rotate clockwise",
  },
  rotateCounter: {
    id: "d6f8664c-2256-41dc-a33d-03f95b373586",
    defaultMessage: "Rotate counter-clockwise",
  },
  zoomIn: {
    id: "a9831fb5-916d-468a-9abf-0e41cacddb5c",
    defaultMessage: "Zoom in",
  },
  zoomOut: {
    id: "edbb1172-95a6-4b15-9e39-01d7bd987e8e",
    defaultMessage: "Zoom out",
  },
  reset: {
    id: "43318d5e-83fd-4bba-b64f-e34582eb0a1e",
    defaultMessage: "Reset position",
  },
  previousDocument: {
    id: "6380e616-aec6-4839-9172-49bc5cb33bd3",
    defaultMessage: "Previous document",
  },
  nextDocument: {
    id: "729f6819-2a83-458f-9571-289ef69ad55e",
    defaultMessage: "Next document",
  },
  closePopout: {
    id: "db944ff9-7db2-4a14-b83e-699af2f763dc",
    defaultMessage: "Close popout viewer",
  },
  documentImageAlt: {
    id: "60571724-f9cb-46b0-82f2-742252d7d2ff",
    defaultMessage: "Identity document image",
  },
});

const SCALE_INCREMENT = 0.2;

function getImageTransform(
  { x, y, dx, dy }: DragLocation,
  rotation: number,
  scale: number,
): string {
  const translateX = x + dx;
  const translateY = y + dy;
  return `translate(${translateX}px, ${translateY}px) rotate(${rotation}deg) scale(${scale})`;
}

export function usePopoutViewerEngine() {
  const viewerRef = useRef<HTMLDivElement>(null);

  // keeps the viewer from being dropped outside of where it will be visible
  const putViewerInBounds = useCallback(() => {
    const viewerRect = viewerRef.current?.getBoundingClientRect();
    const parentRect = viewerRef.current?.parentElement?.getBoundingClientRect();
    if (!viewerRect || !parentRect) {
      return;
    }
    const bounds = {
      minX: 0,
      minY: 0,
      maxX: parentRect.width - viewerRect.width,
      maxY: parentRect.height - viewerRect.height,
    };
    // setViewerLocation will have a value by the time this function is called
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setViewerLocation((current) => {
      const boundedCoordinates = {
        x: Math.max(bounds.minX, Math.min(bounds.maxX, current.x)),
        y: Math.max(bounds.minY, Math.min(bounds.maxY, current.y)),
      };
      return { ...current, ...boundedCoordinates };
    });
  }, []);

  // We render a transparent overlay while dragging the viewer because the
  // pspdfkit view swallows all mouse events and causes really weird dragging
  // behavior. The overlay intercepts the mouse events for us so we don't lose
  // them.
  const [renderOverlay, setRenderOverlay] = useState(false);
  const handleViewerDragEnd = useCallback(() => {
    putViewerInBounds();
    setRenderOverlay(false);
  }, [putViewerInBounds]);
  const {
    dragLocation: viewerLocation,
    setDragLocation: setViewerLocation,
    handleMouseDown: startViewerDrag,
  } = useDragWithLocation({
    onDragEnd: handleViewerDragEnd,
  });

  const handleViewerMouseDown = useCallback((event: MouseEvent) => {
    // Only allow left button drags on the window itself, not the inner controls or other buttons
    if (event.target === viewerRef.current && event.button === 0) {
      setRenderOverlay(true);
      startViewerDrag(event);
    }
  }, []);

  return {
    viewerRef,
    renderOverlay,
    setRenderOverlay,
    viewerLocation,
    setViewerLocation,
    startViewerDrag,
    handleViewerMouseDown,
  };
}

export type Props = {
  onClose: () => void;
  onClosePopout: () => void;
  documentEngine: IdentityDocumentViewerEngine;
  retakeManager?: RetakeManager;
};

export type ControlledProps = Props & {
  popoutEngine: ReturnType<typeof usePopoutViewerEngine>;
};

export function ControlledPopoutViewer({
  onClose,
  onClosePopout,
  popoutEngine: { viewerRef, renderOverlay, viewerLocation, handleViewerMouseDown },
  documentEngine,
  documentEngine: {
    currentPhoto,
    currentPhotoType,
    documentUrl,
    onChangeDocumentSide,
    onNextDocument,
    onPreviousDocument,
  },
  retakeManager,
}: ControlledProps) {
  const intl = useIntl();

  const {
    dragLocation: imageLocation,
    setDragLocation: setImageLocation,
    handleMouseDown: startImageDrag,
  } = useDragWithLocation();
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);

  const rotateCounter = useCallback(() => setRotation((old) => old - 90), []);
  const rotateClockwise = useCallback(() => setRotation((old) => old + 90), []);
  const zoomIn = useCallback(() => setScale((old) => Math.min(3, old + SCALE_INCREMENT)), []);
  const zoomOut = useCallback(() => setScale((old) => Math.max(0.2, old - SCALE_INCREMENT)), []);
  const resetImage = useCallback(() => {
    setImageLocation({ x: 0, y: 0, dx: 0, dy: 0 });
    setRotation(0);
    setScale(1);
  }, []);

  const handleChangeDocumentSide = useCallback(() => {
    onChangeDocumentSide();
    resetImage();
  }, []);

  const calculatedLocation = {
    x: viewerLocation.x + viewerLocation.dx,
    y: viewerLocation.y + viewerLocation.dy,
  };

  const isPdf = documentUrl?.endsWith(".pdf");
  const genericDocumentTitle = getGenericDocumentTitleFromType(currentPhotoType);
  const documentTitle = currentPhoto
    ? getSpecificDocumentTitleFromClaimedType(currentPhoto.documentClaimedType) ||
      genericDocumentTitle
    : genericDocumentTitle;

  return (
    <div
      ref={viewerRef}
      className={classnames(Styles.popoutWindow, {
        [Styles.autoHeight]: !documentUrl,
      })}
      style={{
        transform: `translate(${calculatedLocation.x}px, ${calculatedLocation.y}px)`,
      }}
      onMouseDown={handleViewerMouseDown}
    >
      {renderOverlay && (
        <Portal>
          <Overlay background="transparent" />
        </Portal>
      )}
      <div className={Styles.main}>
        <div className={Styles.titleBar}>
          <IconButton
            className={Styles.whiteText}
            name="x"
            onClick={onClose}
            label={intl.formatMessage(MESSAGES.closeButton)}
          />
          <div className={Styles.title}>
            <IconButton
              className={Styles.whiteText}
              name="caret-left"
              label={intl.formatMessage(MESSAGES.previousDocument)}
              disabled={!onPreviousDocument}
              onClick={() => {
                if (onPreviousDocument) {
                  onPreviousDocument();
                  resetImage();
                }
              }}
            />
            {documentTitle}
            <IconButton
              className={Styles.whiteText}
              name="caret-right"
              label={intl.formatMessage(MESSAGES.nextDocument)}
              disabled={!onNextDocument}
              onClick={() => {
                if (onNextDocument) {
                  onNextDocument();
                  resetImage();
                }
              }}
            />
          </div>
        </div>
        {documentUrl && !retakeManager?.inProgress ? (
          <>
            {isPdf ? (
              <PDFViewerWithControls url={documentUrl} />
            ) : (
              <div className={Styles.imageContainer}>
                <img
                  className={Styles.imageView}
                  src={documentUrl}
                  alt={intl.formatMessage(MESSAGES.documentImageAlt)}
                  style={{ transform: getImageTransform(imageLocation, rotation, scale) }}
                  onMouseDown={startImageDrag}
                />
              </div>
            )}
          </>
        ) : (
          <DocumentPlaceholder engine={documentEngine} retakeManager={retakeManager} />
        )}
      </div>
      {!isPdf && currentPhoto && (
        <div className={Styles.controlBar}>
          {currentPhoto.backPicture && (
            <ControlButton
              aria-label={intl.formatMessage(MESSAGES.viewOtherSide)}
              onClick={handleChangeDocumentSide}
            >
              <Icon name="refresh" />
            </ControlButton>
          )}
          <ControlButton
            aria-label={intl.formatMessage(MESSAGES.rotateCounter)}
            onClick={rotateCounter}
          >
            <Icon name="rotate" />
          </ControlButton>
          <ControlButton
            aria-label={intl.formatMessage(MESSAGES.rotateClock)}
            onClick={rotateClockwise}
          >
            <Icon name="rotate-clockwise" />
          </ControlButton>
          <ControlButton aria-label={intl.formatMessage(MESSAGES.rotateClock)} onClick={zoomIn}>
            <Icon name="add" />
          </ControlButton>
          <ControlButton aria-label={intl.formatMessage(MESSAGES.rotateClock)} onClick={zoomOut}>
            <Icon name="remove" />
          </ControlButton>
          <ControlButton aria-label={intl.formatMessage(MESSAGES.reset)} onClick={resetImage}>
            <Icon name="fullscreen" />
          </ControlButton>
          <ControlButton
            aria-label={intl.formatMessage(MESSAGES.closePopout)}
            onClick={onClosePopout}
          >
            <Icon name="picture-in-picture" />
          </ControlButton>
        </div>
      )}
    </div>
  );
}
