import { memo, useState, type ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { segmentTrack } from "util/segment";
import SROnly from "common/core/screen_reader";
import Icon from "common/core/icon";
import Tooltip from "common/core/tooltip";
import { Checkbox, CheckboxLabel } from "common/core/form/option";
import { useId } from "util/html";

import Styles from "./checklist.module.scss";

type Props = {
  meetingId: string;
  notaryUser: {
    id: string;
    notaryProfile: null | {
      usState: { name: string | null };
    };
  };
  isOpen: boolean;
  onOpenToggle: (fabContainer: "checklist") => void;
};

type ChecklistOptions = {
  meetingId: string;
  notaryStateName: string;
};
type Item = {
  id: string;
  checked: boolean;
  label: ReactElement;
  helpTextContent?: ReactElement;
};
type BasicItemFactory = (state: string) => false | Omit<Item, "checked">;

const STOP_DATE = new Date(2022, 3, 1);
const BASIC_ITEMS: readonly BasicItemFactory[] = Object.freeze([
  () => ({
    id: "avWorking",
    label: (
      <FormattedMessage id="7efa950e-4205-40db-98da-033f8215ad0d" defaultMessage="A/V working" />
    ),
    helpTextContent: (
      <FormattedMessage
        id="8fd9b92b-f577-4074-80ce-89ea9869cacc"
        defaultMessage="A/V is working for signer and notary throughout the session."
      />
    ),
  }),
  () => ({
    id: "onlyInSessionSigners",
    label: (
      <FormattedMessage
        id="ef9a191f-dae0-403c-b2ca-b60215f0763b"
        defaultMessage="Only notarize for signers in session"
      />
    ),
    helpTextContent: (
      <FormattedMessage
        id="e46e22a5-18ae-468e-b1bf-aba1a82f6649"
        defaultMessage="For transactions with multiple signers, the notarization is only for signers who appear in your session."
      />
    ),
  }),
  () => ({
    id: "acceptableIds",
    label: (
      <FormattedMessage
        id="1f076e38-7784-4517-8c06-c24c2fd33719"
        defaultMessage="Acceptable form of ID"
      />
    ),
    helpTextContent: (
      <FormattedMessage
        id="7d762d45-2852-4a99-a29c-384f74e8891e"
        defaultMessage="Signer's ID is acceptable for your state."
      />
    ),
  }),
  () => ({
    id: "matchingSigners",
    label: (
      <FormattedMessage
        id="1072e7f1-7319-438b-9517-bfbfb7cdd746"
        defaultMessage="Signer matches KBA info & ID"
      />
    ),
    helpTextContent: (
      <FormattedMessage
        id="5d5f2d4f-1744-4f1d-bc2c-e8186e8d1400"
        defaultMessage="The signer is the person identified in KBA and shown on the ID."
      />
    ),
  }),
  (usState) => {
    return (
      usState === "Florida" && {
        id: "announcedActType",
        label: (
          <FormattedMessage
            id="728d2f37-eeef-4eaf-a252-fb10b39cacf8"
            defaultMessage="Announce type of notarial act"
          />
        ),
      }
    );
  },
  (usState) => {
    return (
      usState === "Florida" && {
        id: "noBlankSignerFieldsFlorida",
        label: (
          <FormattedMessage
            id="4cef5a66-6777-4a2d-be26-1a9b87a9195d"
            defaultMessage="Documents are not blank or incomplete"
          />
        ),
        helpTextContent: (
          <FormattedMessage
            id="0a8350aa-31df-44d8-b48e-2270010f242d"
            defaultMessage="Florida law prohibits you from notarizing a document that is incomplete or blank. You may write N/A in any field that would otherwise be left blank."
          />
        ),
      }
    );
  },
  (usState) => {
    return (
      usState === "Nevada" && {
        id: "noBlankSignerFieldsNevada",
        label: (
          <FormattedMessage
            id="29a32cea-823d-49e6-a91f-8a5e92799c67"
            defaultMessage="No blanks that will be filled in by signer"
          />
        ),
        helpTextContent: (
          <FormattedMessage
            id="784190d0-ba32-4f3e-a7ba-94c4b8e9dc3d"
            defaultMessage="Nevada law prohibits you from notarizing a document with blanks that will be filled in by the signer. You may write N/A in any field that would otherwise be left blank."
          />
        ),
      }
    );
  },
]);

function useChecklistItems({ meetingId, notaryStateName }: ChecklistOptions) {
  const [checked, setChecked] = useState<Set<Item["id"]>>(new Set());
  const onToggle = ({ id }: Item) => {
    segmentTrack("Notary toggled legal checklist item", {
      meetingId,
      itemId: id,
      direction: checked.has(id) ? "off" : "on",
    });
    setChecked((old) => {
      const modifed = old.has(id) ? [...old].filter((oldId) => oldId !== id) : [...old, id];
      return new Set(modifed);
    });
  };
  const items: Item[] = BASIC_ITEMS.flatMap((factory) => {
    const basicItem = factory(notaryStateName);
    return basicItem ? [{ ...basicItem, checked: checked.has(basicItem.id) }] : [];
  });
  const allChecked = items.length === checked.size;
  return { items, onToggle, allChecked };
}

function NotaryMeetingChecklist({ notaryUser, meetingId, isOpen, onOpenToggle }: Props) {
  const notaryStateName = notaryUser.notaryProfile!.usState.name!;
  const showNewTag = new Date() < STOP_DATE;
  const checkListId = useId();
  const { items, onToggle, allChecked } = useChecklistItems({
    meetingId,
    notaryStateName,
  });
  const handleToggle = () => {
    if (!isOpen) {
      segmentTrack("Notary opened legal checklist", { meetingId });
    }
    onOpenToggle("checklist");
  };
  const toggleButtonCx = classnames(Styles.toggleButton, {
    [Styles.opened]: isOpen,
  });
  return (
    <div className={Styles.checklist}>
      {isOpen && (
        <div className={Styles.list} id={checkListId}>
          <header>
            <FormattedMessage
              id="db22ea91-df6d-4f20-8eae-dce3806d8094"
              defaultMessage="Meeting Reminders"
              tagName="span"
            />
            <Tooltip
              trigger="hover"
              target={
                showNewTag ? (
                  <span className={Styles.newTooltipTarget}>
                    <FormattedMessage
                      id="40645b6f-5701-40ac-bb16-06ee54a23220"
                      defaultMessage="New!"
                    />
                  </span>
                ) : (
                  <Icon className={Styles.tooltipTarget} name="info" />
                )
              }
            >
              <FormattedMessage
                id="8fbfc6fb-6b6f-4aef-b8d3-eaa7f999da92"
                defaultMessage="Use this list of helpful reminders to ensure this notarization complies with the laws of your state. You do not need to check each box to complete the meeting."
                tagName="p"
              />
            </Tooltip>
          </header>
          <ul>
            {items.map((item) => (
              <li key={item.id}>
                <CheckboxLabel
                  checkbox={
                    <Checkbox
                      aria-invalid={!item.checked}
                      checked={item.checked}
                      onChange={() => onToggle(item)}
                    />
                  }
                  label={
                    <span className={item.checked ? Styles.checkboxLabelText : undefined}>
                      {item.label}
                    </span>
                  }
                />
                {item.helpTextContent && (
                  <Tooltip
                    placement="leftTop"
                    trigger="hover"
                    target={<Icon className={Styles.itemHelp} name="info" />}
                  >
                    {item.helpTextContent}
                  </Tooltip>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      <button
        type="button"
        className={toggleButtonCx}
        aria-expanded={Boolean(isOpen)}
        aria-controls={checkListId}
        onClick={handleToggle}
      >
        <SROnly>
          <FormattedMessage
            id="40e426c7-3881-4d27-94c1-b5cddc5b55e6"
            defaultMessage="{isOpen, select, true{Close} other{Open}} Legal Checklist"
            values={{ isOpen: Boolean(isOpen) }}
          />
        </SROnly>
        <Icon name={isOpen ? "x" : "annotate"} />
      </button>
      {allChecked && (
        <div className={Styles.allChecked}>
          <SROnly>
            <FormattedMessage
              id="092d58d0-2ba0-48b4-a5ce-4f9a3dc7dbcf"
              defaultMessage="Everything complete"
            />
          </SROnly>
          <Icon name="tick" />
        </div>
      )}
    </div>
  );
}

export default memo(NotaryMeetingChecklist);
