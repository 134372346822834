import { FormattedMessage } from "react-intl";

import Tool from "common/meeting/toolbar/tool";

// All start with signer-designation
const DESIGNATION_SIGNATURE = "signer-designation-signature";
const DESIGNATION_INITIALS = "signer-designation-initials";
const DESIGNATION_DATE = "signer-designation-date";
const DESIGNATION_FREE_TEXT = "signer-designation-free-text";

type Props = {
  disabled: boolean;
};

export function SignatureDesignationTool({ disabled }: Props) {
  return (
    <Tool
      tool={DESIGNATION_SIGNATURE}
      ctrlMod
      shortcutKey="s"
      label={
        <FormattedMessage id="a73fec31-e052-4962-970d-64c827bd5700" defaultMessage="Sign Here" />
      }
      disabled={disabled}
      iconName="sign"
      automationId="notary-toolbar-signer-signature"
    />
  );
}

export function InitialsDesignationTool({ disabled }: Props) {
  return (
    <Tool
      tool={DESIGNATION_INITIALS}
      ctrlMod
      shortcutKey="i"
      label={
        <FormattedMessage id="7413b922-cdbf-4e41-862d-7a4e8f69c3c9" defaultMessage="Initial Here" />
      }
      disabled={disabled}
      iconName="initials"
      automationId="notary-toolbar-signer-initials"
    />
  );
}

export function DateDesignationTool({ disabled }: Props) {
  return (
    <Tool
      tool={DESIGNATION_DATE}
      ctrlMod
      shortcutKey="d"
      label={
        <FormattedMessage id="3bc7a33f-6a29-4e9f-ad0a-3332bcdd4004" defaultMessage="Date Signed" />
      }
      disabled={disabled}
      iconName="toolbar-date"
      automationId="notary-toolbar-signer-date"
    />
  );
}

export function FreeTextDesignationTool({ disabled }: Props) {
  return (
    <Tool
      tool={DESIGNATION_FREE_TEXT}
      ctrlMod
      shortcutKey="u"
      label={
        <FormattedMessage id="d3702687-8639-47f8-82ab-7e36cf1f3f63" defaultMessage="Fill In Here" />
      }
      disabled={disabled}
      iconName="text-box"
      automationId="notary-toolbar-signer-free-text"
    />
  );
}
