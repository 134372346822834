import { FormattedMessage } from "react-intl";
import { BehaviorSubject, concatMap, forkJoin, from, map, skipWhile, tap, throwError } from "rxjs";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useFeatureFlag } from "common/feature_gating";
import MultiUploader, {
  type UploadedDocument,
} from "common/document/multidoc_uploader/multi_uploader";
import DocumentUploadHandler, {
  type DocumentUploaderHandlerRenderProps,
} from "common/document/multidoc_uploader/document_upload_handler";
import {
  isDocX,
  lookupMimeTypeFromName,
  softWarningOnDocxUpload,
} from "common/document/uploader/document_item_util";
import CreateDocumentsMutation from "common/transactions/graphql/mutations/create_documents_mutation.graphql";
import AddDocumentsToTransactionMutation from "common/transactions/graphql/mutations/add_documents_to_transaction_mutation.graphql";
import { uploadDocumentToS3 } from "util/uploader";
import { ProcessingStates } from "graphql_globals";
import { useDocumentPoller } from "common/document/state_poller";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES } from "constants/notifications";
import { captureException } from "util/exception";
import { useMutation } from "util/graphql";
// eslint-disable-next-line import/no-restricted-paths
import CreateOrganizationTransactionV2 from "business_portal/transactions/new/create_org_transaction_v2.mutation.graphql";
import { businessTransactionEditRoute } from "util/routes";
import { useActiveOrganization } from "common/account/active_organization";
import { usePermissions } from "common/core/current_user_role";
import { segmentTrack } from "util/segment";

import { Section } from "../common/section";

export function GetStartedDocumentUploader({ isNewProOrg }: { isNewProOrg: boolean }) {
  const { hasPermissionFor } = usePermissions();
  const getStartedDocUploadEnabled =
    useFeatureFlag("biz-pro-doc-upload-expt") &&
    isNewProOrg &&
    hasPermissionFor("viewOrganizationTransactions");
  const navigate = useNavigate();

  const createDocumentsMutateFn = useMutation(CreateDocumentsMutation);
  const createTransactionFn = useMutation(CreateOrganizationTransactionV2);
  const addDocumentsToTransactionFn = useMutation(AddDocumentsToTransactionMutation);

  const startPolling = useDocumentPoller();
  const isFirstUpload$ = new BehaviorSubject<boolean>(true);

  const [activeOrganizationId] = useActiveOrganization();
  const documents = useRef<UploadedDocument[]>([]);

  if (!getStartedDocUploadEnabled) {
    return null;
  }

  function onUpload(docs: UploadedDocument[]) {
    documents.current = [...documents.current, ...docs];
  }

  function uploadStrategy(file: File) {
    if (isDocX(file.name)) {
      softWarningOnDocxUpload();
    }

    return from(
      uploadDocumentToS3(file, {
        temporary: true,
      }),
    ).pipe(
      concatMap((s3Payload) => {
        return s3Payload?.key
          ? from(
              createDocumentsMutateFn({
                variables: {
                  input: {
                    fileHandle: s3Payload.key,
                    organizationId: activeOrganizationId!,
                    pdfBookmarked: false,
                  },
                },
              }),
            ).pipe(
              concatMap(({ data }) => {
                const observables$ = data!.createDocuments!.documents.map(
                  ({ id, bundlePosition }, index) => {
                    return startPolling(id).pipe(
                      skipWhile((doc) => doc.processingState === ProcessingStates.PENDING),
                      map((doc) => {
                        if (doc.processingState === ProcessingStates.FAILED) {
                          throw new Error("Failed to process file");
                        }
                        const url = doc.s3OriginalAsset!.url!;
                        return {
                          id,
                          name: doc.name!,
                          bundlePosition: bundlePosition || index,
                          status: doc.processingState,
                          classification: doc.classification,
                          mimeType: lookupMimeTypeFromName(doc.name),
                          metadata: doc.metadata,
                          url,
                          resource: url,
                        };
                      }),
                    );
                  },
                );
                return forkJoin(observables$);
              }),
            )
          : throwError(() => new Error("Failed to upload file to s3"));
      }),
      map((docs) => docs.sort((a, b) => a.bundlePosition - b.bundlePosition)),
      tap({
        next: (docArray) => {
          const isFirstUpload = isFirstUpload$.getValue();
          if (isFirstUpload) {
            isFirstUpload$.next(true);
          }
          onUpload(docArray);
        },
        error: (error) => {
          captureException(error);
          pushNotification({
            message: (
              <FormattedMessage
                id="9e88c460-fbf8-4ea6-85a0-ae335774efa0"
                defaultMessage="Failed to upload documents. Please try again."
              />
            ),
            subtype: NOTIFICATION_SUBTYPES.ERROR,
          });
        },
      }),
    );
  }

  const createAndRedirectToNewTransaction = async () => {
    try {
      const response = await createTransactionFn({
        variables: {
          input: {
            organizationId: activeOrganizationId!,
            transaction: {},
            customers: [{}],
          },
        },
      });
      const transaction = response.data!.createOrganizationTransactionV2!.organizationTransaction;
      await addDocumentsToTransactionFn({
        variables: {
          input: {
            documentIds: documents.current.map((doc) => doc.id),
            options: {
              disableSplitting: false,
              permissions: { witnessRequired: false },
              requirements: {
                esign: false,
                notarizationRequired: true,
                proofingRequired: false,
                signingRequiresMeeting: true,
              },
            },
            transactionId: transaction.id,
          },
        },
      });
      segmentTrack("Business Get Started - Create transaction from docs [Web]");
      navigate(businessTransactionEditRoute(transaction), { replace: true });
    } catch (error) {
      captureException(error);
      pushNotification({
        message: (
          <FormattedMessage
            id="af787a84-cb75-4f45-8b9d-da655ea5a5b9"
            defaultMessage="Failed to create transaction. Please try again."
          />
        ),
        subtype: NOTIFICATION_SUBTYPES.ERROR,
      });
    }
  };

  function onDocumentDeleteCb(doc: UploadedDocument) {
    const docId = doc.userDocument?.id;
    const filteredDocs = documents.current.filter(({ id }) => id !== docId);
    documents.current = filteredDocs;
  }

  return (
    <Section
      title={
        <FormattedMessage
          id="bd118775-1b1e-497f-be74-9e6e65fccecc"
          defaultMessage="Upload a document to start a notarization request"
        />
      }
    >
      <DocumentUploadHandler uploadStrategy={uploadStrategy}>
        {({
          uploadedDocuments$,
          onSelectFiles,
          onDocumentDelete,
        }: DocumentUploaderHandlerRenderProps) => {
          return (
            <MultiUploader
              aria-label={
                <FormattedMessage
                  id="b680442c-6d94-4bc7-b70a-b40aa408afa5"
                  defaultMessage="Document uploader"
                />
              }
              onSelectFiles={onSelectFiles}
              onDocumentDelete={(doc) => {
                onDocumentDeleteCb(doc);
                onDocumentDelete(doc);
              }}
              uploadedDocuments$={uploadedDocuments$}
              completeStrategy={() => {
                return Promise.resolve(createAndRedirectToNewTransaction());
              }}
              enableScanning
              analyticsPrefix="Business Get Started"
            />
          );
        }}
      </DocumentUploadHandler>
    </Section>
  );
}
