import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import type { ReactElement } from "react";

import { useMutation } from "util/graphql";
import WorkflowModal from "common/modals/workflow_modal";
import { Pill } from "common/core/pill_tabs";
import { NotarizeNetwork } from "common/core/logo/notarize_network";
import Button from "common/core/button";
import { pushNotification } from "common/core/notification_center/actions";
import { useForm, type UseFormReturn } from "common/core/form";
import { type Address } from "common/core/form/address";
import TooltipOverlay from "common/core/tooltip/overlay";
import { useId } from "util/html";

import type {
  NotaryAddWitness as Meeting,
  NotaryAddWitness_meetingParticipants as MeetingParticipant,
} from "../index_fragment.graphql";
import CreateWitnessMutation from "../create_witness_mutation.graphql";
import { OdnRemoteWitness, OdnRemoteWitnessPaymentWarning } from "./odn_remote_witness";
import Styles from "./index.module.scss";
import { useNotaryMeetingContext } from "../../context";
import NotaryWitness from "./notary_witness";
import SignerWitness from "./signer_witness";

type Props = {
  meeting: Meeting;
  onClose: () => void;
  onRequestOdnWitness: (numWitnesses: number) => void;
  isLoading: boolean;
};

type ChooseSignerPartyFormValues = {
  meetingParticipantId: string;
};

type ModalFormType = "no-selection" | "odn-remote-witness" | "notary-witness" | "signer-witness";

export type SignerParticipant = {
  __typename: "SignerParticipant";
  firstName: string | null;
  id: string;
  lastName: string | null;
  parentId: string | null;
  userId: string | null;
};

export type WitnessFormValues = {
  type: ModalFormType;
  witnessCount: number;
  isColocated?: boolean;
  firstName: string;
  lastName: string;
  email: string;
  inUS: "yes";
  address: Address;
  termsAgree: "yes";
  signerIdentityId: string;
};

export type UpdateNotaryWitnessProps = { isColocated?: boolean };

type ModalBodyProps = {
  form: UseFormReturn<WitnessFormValues>;
  signers: SignerParticipant[];
  witnesses: MeetingParticipant[];
};

const messages = defineMessages({
  collectingSignerInfo: {
    id: "224560d7-d693-4571-9c70-3bf7254df649",
    defaultMessage: "We are collecting the witness' information with the signer",
  },
} as const);

function ModalBody({ form, signers, witnesses }: ModalBodyProps): ReactElement | null {
  const { type, witnessCount, isColocated } = form.watch();

  switch (type) {
    case "odn-remote-witness":
      return (
        <OdnRemoteWitness
          formWitnessCount={witnessCount}
          updateFormWitnessCount={(n) => form.setValue("witnessCount", n)}
          witnessesInMeeting={witnesses}
        />
      );
    case "signer-witness":
      return <SignerWitness signers={signers} form={form} />;
    case "notary-witness":
      return <NotaryWitness isColocated={isColocated} form={form} />;
    case "no-selection":
      return null;
  }
}

function isSigner(part: MeetingParticipant): part is SignerParticipant {
  return part.__typename === "SignerParticipant";
}

function isWitness(part: MeetingParticipant) {
  return part.__typename === "WitnessParticipant";
}

function AddWitnessModalV2({
  meeting,
  onClose,
  onRequestOdnWitness,
  isLoading,
}: Props): ReactElement {
  const signers = meeting.meetingParticipants.filter(isSigner);
  const witnesses = meeting.meetingParticipants.filter(isWitness);
  const form = useForm<WitnessFormValues>({
    defaultValues: {
      type: "no-selection",
      witnessCount: 1,
      signerIdentityId: signers[0].id,
    },
  });
  const { type, witnessCount } = form.watch();
  const { isValid } = form.formState;
  const odnRemoteWitnessDisabled =
    !meeting.documentBundle?.organizationTransaction.publicOrganization.remoteOdnWitnessEnabled;
  const signersWitnessDisabled =
    meeting.documentBundle?.organizationTransaction.publicOrganization
      .identityVerifiedWitnessEnabled;

  const addColocatedWitnessMutateFn = useMutation(CreateWitnessMutation);
  const { channel, analytics } = useNotaryMeetingContext();
  const intl = useIntl();

  // Only REAL transactions with the state of Florida are allowed to call in a notarys witness
  const orgTransactionPropertyState =
    meeting.documentBundle?.organizationTransaction.streetAddress?.state.toLowerCase();
  const isRemoteWitnessingPossible =
    orgTransactionPropertyState === "florida" || orgTransactionPropertyState === "fl";

  const addWitness = (formValues: WitnessFormValues) => {
    const input = formValues.isColocated
      ? {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          address: formValues.address,
        }
      : {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          email: formValues.email,
        };

    return addColocatedWitnessMutateFn({
      variables: {
        input: { meetingId: meeting.id, ...input },
      },
    });
  };

  const handleChooseSignerPartyForWitness = ({
    meetingParticipantId,
  }: ChooseSignerPartyFormValues) => {
    channel.sendMessage("create_witness", { meeting_participant_id: meetingParticipantId });
    analytics.onAddWitness();
    pushNotification({
      message: intl.formatMessage(messages.collectingSignerInfo),
      subtype: "SUCCESS",
    });
  };

  const submitAddWitness = (formValues: WitnessFormValues) => {
    switch (formValues.type) {
      case "odn-remote-witness":
        return onRequestOdnWitness(formValues.witnessCount);
      case "signer-witness":
        handleChooseSignerPartyForWitness({
          meetingParticipantId: formValues.signerIdentityId,
        });
        return onClose();
      case "notary-witness":
        return addWitness(formValues).then(() => onClose());
      case "no-selection":
        throw new Error("Attempted to add witness without selecting witness type");
    }
  };

  const submitDisabled = type === "no-selection" || !isValid || isLoading;
  const odnWitnessDisabledTooltipId = useId();
  const signerWitnessDisabledTooltipId = useId();

  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="1bae6475-0e2d-4237-83cf-dc56f2392466"
          defaultMessage="Add a witness"
        />
      }
      closeBehavior={{ onClose, tag: "with-button" }}
      large
    >
      <form onSubmit={form.handleSubmit(submitAddWitness)}>
        <div className={Styles.instruction}>
          <FormattedMessage
            id="2e2d72ea-846a-4db0-9b36-38971e0af37b"
            defaultMessage="Select how you would like to add a witness"
          />
        </div>
        <div className={Styles.pills}>
          <div className={Styles.odnwTooltipContainer}>
            <Pill
              onClick={() => {
                form.setValue("witnessCount", 1);
                form.setValue("type", "odn-remote-witness");
              }}
              selected={type === "odn-remote-witness"}
              disabled={odnRemoteWitnessDisabled}
              aria-describedby={odnRemoteWitnessDisabled ? odnWitnessDisabledTooltipId : undefined}
            >
              <NotarizeNetwork data-automation-id="odn-remote-witness-button" />
            </Pill>
            {odnRemoteWitnessDisabled && (
              <TooltipOverlay id={odnWitnessDisabledTooltipId} trigger="hover" placement="top">
                <FormattedMessage
                  id="d6814987-21ce-4826-82c6-df1bbab4bd9b"
                  defaultMessage="Unavailable for this transaction"
                />
              </TooltipOverlay>
            )}
          </div>

          <div className={Styles.odnwTooltipContainer}>
            <Pill
              onClick={() => form.setValue("type", "signer-witness")}
              selected={type === "signer-witness"}
              disabled={signersWitnessDisabled}
              aria-describedby={signersWitnessDisabled ? signerWitnessDisabledTooltipId : undefined}
            >
              <FormattedMessage
                id="2683b074-a09a-47fa-8d5e-b42ab69a3bae"
                defaultMessage="Signer's witness"
              />
            </Pill>
            {signersWitnessDisabled && (
              <TooltipOverlay id={signerWitnessDisabledTooltipId} trigger="hover" placement="top">
                <FormattedMessage
                  id="5ec12e64-82db-4753-9d18-00b66c974407"
                  defaultMessage="Unavailable for this transaction"
                />
              </TooltipOverlay>
            )}
          </div>
          {isRemoteWitnessingPossible && (
            <Pill
              onClick={() => {
                form.setValue("isColocated", undefined);
                form.setValue("type", "notary-witness");
              }}
              selected={type === "notary-witness"}
            >
              <FormattedMessage
                id="19eaf4c8-abe7-49a9-a4e2-5e78c0a9d549"
                defaultMessage="Witness I know"
              />
            </Pill>
          )}
        </div>
        <ModalBody form={form} signers={signers} witnesses={witnesses} />
        <div className={Styles.footer}>
          {type === "odn-remote-witness" && (
            <OdnRemoteWitnessPaymentWarning
              witnessCount={witnessCount}
              payer={meeting.documentBundle!.payer}
              odnRemoteWitnessPrice={meeting.documentBundle!.tier.prices.remoteOdnWitness}
            />
          )}
          <Button
            key="submit"
            type="submit"
            buttonColor="action"
            variant="primary"
            disabled={submitDisabled}
          >
            <FormattedMessage
              id="281709f6-b3ba-4e0c-8854-2f164cf3603e"
              defaultMessage="Add witness"
            />
          </Button>
        </div>
      </form>
    </WorkflowModal>
  );
}

export default AddWitnessModalV2;
